import React from 'react'
import { CSpinner } from '@coreui/react'
import './Loading.style.scss'

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-container">
        <CSpinner color="primary" size="sm" style={{ width: '3rem', height: '3rem' }} />
      </div>
    </div>
  )
}

export default Loading
