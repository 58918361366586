import PropTypes from 'prop-types'
import React, { createContext, useContext, useState } from 'react'
import Loading from './Loading'

const LoadingContext = createContext()

export const useLoading = () => {
  return useContext(LoadingContext)
}

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && <Loading />}
      {children}
    </LoadingContext.Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
